import React from "react";
import { string, bool, shape, element } from "prop-types";
import LpLayout from "../components/Layout/lp-layout";
import SEO from "../components/seo";
import LeadForm from "../components/Forms/LeadForm";
import Image from "../components/image";
import data from "../data/forms/lead-form";
import ogImage from "../images/global_assets/og-image.png";

const shortData = data.filter((item) => item.name !== "city");

const formDataToSend = [
  "firstname",
  "email",
  "phone",
  "company",
  "city",
  "text_messages",
  "industry",
];

const shortFormDataToSend = [
  "firstname",
  "email",
  "phone",
  "company",
  "text_messages",
  "industry",
];

export default function LpDemo(props) {
  const {
    seoProps,
    useFormValueAsDataLayerEventName,
    formValueForDataLayerEventName,
    dataLayerEventNameAppendix,
    leadType,
    formHeader,
    formSubHeader,
    formId,
    formName,
    useShortForm,
    phoneText,
    phoneNumber,
    thankYouPath,
    imageName,
    formCta,
    children,
  } = props;

  return (
    <LpLayout phoneNumber={phoneNumber} phoneText={phoneText}>
      <SEO
        title={seoProps.title}
        image={seoProps.image}
        robotsData={seoProps.robotsData}
      />
      <section className="demo" style={{ marginTop: 0, paddingTop: 0 }}>
        <div className="demoLP__content-wrapper">
          <div className="demoLP__heading-img">
            <Image imageName={imageName} />
          </div>
          <div className="form-wrapper">
            <LeadForm
              leadType={leadType}
              header={formHeader}
              thankYouPath={thankYouPath}
              subHeader={formSubHeader}
              formCta={formCta}
              formId={formId}
              formName={formName}
              formData={data}
              formItemsToSend={
                useShortForm ? shortFormDataToSend : formDataToSend
              }
              useFormValueAsDataLayerEventName={
                useFormValueAsDataLayerEventName
              }
              formValueForDataLayerEventName={formValueForDataLayerEventName}
              dataLayerEventNameAppendix={dataLayerEventNameAppendix}
              lineOfBusiness="Venues"
            />
          </div>
        </div>
        <section style={{ marginTop: 120 }}>{children}</section>
      </section>
    </LpLayout>
  );
}

LpDemo.propTypes = {
  seoProps: shape({
    title: string,
    image: string,
    robotsData: string,
  }),
  leadType: string,
  formHeader: string,
  formSubHeader: string,
  formId: string,
  formName: string,
  useFormValueAsDataLayerEventName: bool,
  formValueForDataLayerEventName: string,
  dataLayerEventNameAppendix: string,
  useShortForm: bool,
  phoneNumber: string,
  phoneText: string,
  thankYouPath: string,
  imageName: string,
  children: element,
  formCta: string,
};

LpDemo.defaultProps = {
  seoProps: {
    title: "Learn more",
    image: `https://spoton.com/${ogImage}`,
    robotsData: "noindex, nofollow",
  },
  leadType: "",
  formHeader: "Get your free demo",
  formSubHeader:
    "Learn how SpotOn products can help you run and grow your business.",
  formId: "",
  formName: "Lead Form",
  useFormValueAsDataLayerEventName: false,
  formValueForDataLayerEventName: "",
  dataLayerEventNameAppendix: "-lead",
  useShortForm: false,
  phoneText: "(888) 224-6506",
  phoneNumber: "+18882246506",
  thankYouPath: "/thank-you",
  imageName: "demo_image.png",
  formCta: "Learn more",
  children: "",
};
