import React from "react";
import loadable from "@loadable/component";
import LpDemoLayout from "../../../page-components/DemoPageLayout";
import { milbLpTrusted, milbLPTestimonial } from "../../../data/landing/milb";
import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";

const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnersEnerprise-stadiums")
);
const InlineQuote = loadable(() => import("../../../components/InlineQuote"));

export default function DemoPageSideKick() {
  return (
    <LpDemoLayout
      leadType="lead-gen"
      dataLayerEventNameAppendix="-lead"
      formSubHeader="Increase revenue, cut labor costs, and gain better data—while shortening lines and creating an unforgettable fan experience."
      formId="368fc7fd-6e19-450c-8bca-9a03976d20d9"
      useShortForm
      thankYouPath="/lp/milb/thank-you"
      formHeader="Book your free consultation"
      phoneNumber="+18775594225"
      phoneText="(877) 559-4225"
      imageName="milb-lp-demo.jpg"
      formCta="Learn more"
    >
      <section style={{ marginTop: "80px" }}>
        <TrustedNumbers numbersArray={milbLpTrusted} />
      </section>
      <section style={{ marginTop: "40px" }}>
        <InlineQuote sectionData={milbLPTestimonial} />
      </section>
      <section style={{ marginBottom: 80 }}>
        <PartnerLogos />
      </section>
    </LpDemoLayout>
  );
}
